<template>
  <Main center>
    <div class="terms">
      <H h2>{{ getLocal(language, 'terms.header') }}</H>

      <P body2 class="terms__info">{{ getLocal(language, 'terms.info') }}</P>

      <div v-if="error" class="terms__error">
        <Toast error>{{ error }}</Toast>
      </div>

      <div class="terms__agreements">
        <Checkbox v-model:checked="esign"
          >{{ getLocal(language, 'terms.check1.text1') }}
          <A to="/terms/esign">{{ getLocal(language, 'terms.check1.text2') }}</A
          >, <A to="/terms/privacy-notice">{{ getLocal(language, 'terms.check1.text3') }}</A
          >, <A to="/terms/terms-and-conditions">{{ getLocal(language, 'terms.check1.text4') }}</A
          >, <A to="/terms/communications-policy">{{ getLocal(language, 'terms.check1.text5') }}</A
          >, {{ getLocal(language, 'terms.check1.text6') }}
          <A :href="getLocal(language, 'terms.check1.policy_sms')" blank>{{
            getLocal(language, 'terms.check1.text7')
          }}</A></Checkbox
        >

        <Checkbox v-model:checked="fcra">{{ getLocal(language, 'terms.check2') }}</Checkbox>

        <Checkbox v-model:checked="contact"
          >{{ getLocal(language, 'terms.check2') }}
          <A to="/terms/contact">{{ getLocal(language, 'terms.policy') }}</A></Checkbox
        >
      </div>
    </div>

    <template v-slot:footer>
      <div class="terms__footer">
        <div class="terms__back">
          <Button v-if="prevRoute" @click="prevClick" :disabled="isLoading" link icon>
            <template v-slot:icon>
              <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 1L1.75 5.25 6 9.5"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>

            <span>{{ getLocal(language, 'back') }}</span>
          </Button>
        </div>

        <div class="terms__cta">
          <Button large expanded :loading="isLoading" :disabled="isLoading || !ready" @click="nextClick">Apply</Button>
        </div>
      </div>
    </template>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import A from '../components/common/A'
import Button from '../components/common/Button.vue'
import Checkbox from '../components/common/Checkbox.vue'
import Toast from '../components/common/Toast.vue'
import H from '../components/common/H.vue'
import P from '../components/common/P.vue'
import Main from '../components/Main'
import { unformatMoney } from '../helpers/general'

export default {
  name: 'Terms',
  components: {
    A,
    Button,
    Checkbox,
    Toast,
    H,
    P,
    Main
  },
  setup() {
    const store = useStore()

    return {
      store,
      error: ref(''),
      esign: ref(store.state.application.agreementEsign),
      fcra: ref(store.state.application.agreementFcra),
      contact: ref(store.state.application.agreementContact),
      isLoading: ref(false)
    }
  },
  methods: {
    getLocal,
    async createApplication() {
      this.writeToStore()

      const resp = await this.store.dispatch('application/sendApplication')

      this.isLoading = false

      if (!resp.success) {
        this.error = resp.message
        return
      }

      const { status } = resp.data

      this.store.dispatch('application/setApplicationDetails', resp.data)

      const path = this.$route.meta.next(status)

      if (path) {
        this.$router.push({ path })
      } else {
        this.error = 'Unknown API status response'
      }
    },
    nextClick() {
      this.isLoading = true

      const application = this.store.state.application
      const user = this.store.state.user

      this.store.dispatch('trackEvent', {
        eventName: 'Submit Application Direct',
        payload: {
          source: application.source,
          personal_monthly_income: unformatMoney(application.monthlyIncome),
          id_type: application.idType.value,
          zipcode: application.zipCode,
          user_type: user.userType,
          state: application.state.value,
          email: application.emailAddress,
          date_of_birth: application.dateOfBirth,
          first_name: application.firstName,
          last_name: application.lastName,
          merchant_id: application.merchantId,
          store_id: application.storeId
        }
      })

      this.createApplication()
    },
    prevClick() {
      this.writeToStore()
      this.$router.push({ path: this.prevRoute })
    },
    writeToStore() {
      this.store.commit('application/setField', {
        field: 'agreementEsign',
        value: this.esign
      })
      this.store.commit('application/setField', {
        field: 'agreementFcra',
        value: this.fcra
      })
      this.store.commit('application/setField', {
        field: 'agreementContact',
        value: this.contact
      })
    }
  },
  computed: {
    ready() {
      return this.esign && this.fcra
    },
    prevRoute() {
      return this.$route.meta.prev
    },
    language() {
      return this.$store.getters.getLanguage
    }
  }
}
</script>

<style lang="scss" scoped>
.terms {
  &__info {
    margin-top: 16px;
  }

  &__error {
    margin-top: 40px;
  }

  &__agreements {
    margin-top: 32px;

    @include media-query(sm) {
      margin-top: 72px;
    }

    > * + * {
      margin-top: 12px;

      @include media-query(sm) {
        margin-top: 24px;
      }
    }
  }

  &__payment {
    margin-top: 40px;

    @include media-query(sm) {
      margin-top: 72px;
    }
  }

  &__card {
    margin-top: 32px;

    @include media-query(sm) {
      margin-top: 48px;
    }
  }

  &__footer {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__back {
    flex: 1 0 auto;
    margin-right: 32px;
  }

  &__cta {
    flex: 0 1 360px;
  }
}
</style>
