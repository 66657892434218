<template>
  <ul class="input-digits">
    <li v-for="(value, index) in values" :key="`input${index}`" class="input-digits__digit">
      <Input
        v-model:value="values[index]"
        :ref="setInputRef"
        @update:value="onChange(index, values[index])"
        @keyup.delete="onDelete(index, values[index])"
        @focus="onFocus(index)"
        mask="#"
        type="text"
        inputmode="numeric"
        digit
        :error="error"
      />
    </li>
  </ul>
</template>

<script>
import Input from './Input.vue'

export default {
  data() {
    return {
      values: [],
      focused: 0,
      inputs: []
    }
  },
  props: {
    length: Number,
    error: Boolean
  },
  components: {
    Input
  },
  methods: {
    setInputRef(el) {
      if (el) {
        this.inputs.push(el)
      }
    },
    onFocus(index) {
      this.focused = index
    },
    onChange(index, value) {
      if (index < this.values.length - 1 && value !== '') {
        this.inputs[index + 1].setFocus()
      }

      if (index === this.values.length - 1 && this.ready) {
        this.$emit('finish')
      }
    },
    onDelete(index, value) {
      if (value === '' && index > 0) {
        this.inputs[index - 1].setFocus()
      }
    }
  },
  computed: {
    inputValue() {
      return this.values.join('')
    },
    ready() {
      return this.values.every((item) => item !== '')
    }
  },
  watch: {
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  },
  mounted() {
    this.values = Array(this.length).fill('')

    this.$nextTick(() => this.inputs[this.focused].setFocus())
  }
}
</script>

<style lang="scss" scoped>
.input-digits {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;

  > * + * {
    margin-left: 16px;
  }

  &__item {
    flex: 0 0 auto;
  }
}
</style>
