<template>
  <div
    class="checkbox"
    :class="{
      'checkbox--border': border
    }"
  >
    <div class="checkbox__control">
      <input
        class="checkbox__input"
        :id="uuid"
        type="checkbox"
        :checked="checked"
        ref="input"
        @change="$emit('update:checked', $event.target.checked)"
        :disabled="disabled"
      />

      <label class="checkbox__label" :for="uuid"></label>
    </div>

    <p class="checkbox__description">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    checked: Boolean,
    border: Boolean,
    disabled: Boolean
  }
}
</script>

<style lang="scss">
.checkbox {
  $this: &;

  display: flex;
  flex-direction: row;

  &__control {
    flex: 0 0 auto;
    position: relative;
    width: 22px;
    height: 22px;
  }

  &__input {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    margin: 0;
    padding: 0;
    width: 1px;

    &:focus {
      + #{$this}__label,
      + #{$this}__label::after {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    &:checked {
      + #{$this}__label {
        &::after {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fafbfc;
    border: 1px solid #b0b7c3;
    border-radius: 4px;
    cursor: inherit;
    line-height: 18px;

    &::after {
      visibility: hidden;
      opacity: 0;
      content: '';
      position: absolute;
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      background-color: #22c58b;
      border: inherit;
      border-color: #22c58b;
      border-radius: inherit;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.338.301a.969.969 0 011.357-.046.939.939 0 01.047 1.339l-6.72 7.105a.97.97 0 01-1.327.073L.335 5.93a.939.939 0 01-.104-1.336.969.969 0 011.354-.103l2.661 2.251L10.338.301z' fill='%23fff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50%;
    }
  }

  &__description {
    flex: 1 1 auto;
    margin-left: 20px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    color: get-color(text-2);
  }

  &--border {
    border: 1px solid #d9dade;
    padding: 16px;
    border-radius: 8px;
  }
}
</style>
