<template>
  <Main center>
    <Start>
      <template v-slot:header>
        <H h2>{{ getLocal(language, 'codeVerification.header') }}</H>

        <P class="verification__info">
          {{ getLocal(language, 'codeVerification.info') }}
          {{ phoneNumber }}
        </P>

        <div class="verification__cta">
          <A :to="$route.meta.prev">{{ getLocal(language, 'codeVerification.cta') }}</A>
        </div>
      </template>

      <template v-slot:main>
        <div class="verification__input">
          <div v-if="info" class="verification__toast">
            <Toast success>{{ info }}</Toast>
          </div>

          <InputDigits :length="6" :error="!!errorMsg" v-model:value="code" :key="resetCode" @finish="onFinish" />
        </div>

        <div class="verification__input-info">
          <p v-if="!!errorMsg" class="verification__error">{{ errorMsg }}</p>

          <A href="#" @click.prevent="resendOtp">{{ getLocal(language, 'codeVerification.otpText') }}</A>
        </div>
      </template>

      <template v-slot:footer>
        <Button ref="submit" large expanded :disabled="!ready || loading" :loading="loading" @click="login">{{
          getLocal(language, 'codeVerification.buttonText')
        }}</Button>
      </template>
    </Start>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import Button from '../components/common/Button.vue'
import H from '../components/common/H.vue'
import P from '../components/common/P.vue'
import A from '../components/common/A.vue'
import InputDigits from '../components/common/InputDigits.vue'
import Main from '../components/Main.vue'
import Start from '../components/Start.vue'
import Toast from '../components/common/Toast.vue'

export default {
  name: 'CodeVerification',
  setup() {
    const store = useStore()

    return {
      resetCode: ref(0),
      store,
      errorMsg: ref(''),
      info: ref(''),
      phoneNumber: ref(store.state.user.phoneNumber),
      code: ref(''),
      loading: ref(false),
      previouslySavedPhoneNumber: ref(store.state.user.previouslySavedPhoneNumber),
      submit: ref(null)
    }
  },
  components: {
    Button,
    H,
    P,
    A,
    InputDigits,
    Main,
    Start,
    Toast
  },
  methods: {
    getLocal,
    async login() {
      this.loading = true

      const isExistingUser = !!this.store.getters['user/getAuth']?.authorization

      const resp = await this.store.dispatch('user/login', {
        token: this.code,
        newPhoneNumberToSave: this.previouslySavedPhoneNumber ? this.phoneNumber : null,
        phone: this.phoneNumber
      })

      if (resp.success) {
        if (isExistingUser) {
          await this.store.dispatch('redirectOnStatus')
        } else {
          this.$router.push('about-you')
        }

        this.loading = false
      } else {
        this.errorMsg = resp.message
        this.loading = false
      }
    },
    async resendOtp() {
      this.errorMsg = ''
      this.resetCode += 1
      this.code = ''

      const resp = await this.store.dispatch('user/resendOtp')

      this.info = resp.data.message
    },
    onFinish() {
      this.submit.setFocus()
    }
  },
  computed: {
    ready() {
      return this.code.length === 6 ? true : false
    },
    language() {
      return this.$store.getters.getLanguage
    }
  }
}
</script>

<style lang="scss" scoped>
.verification {
  &__input {
    margin: 0 auto;
    max-width: 378px;
  }

  &__toast {
    margin-bottom: 24px;
  }

  &__input-info {
    margin-top: 16px;
  }

  &__error {
    color: get-color(primary-4);
  }

  &__info,
  &__cta {
    margin-top: 16px;
  }
}
</style>
